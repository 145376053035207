import React, {useState} from 'react';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';

const Counter = ({initialCount = 1, min = 0, max = 100, onChange}) => {
    const [count, setCount] = useState(initialCount);

    const increment = () => {
        if (count < max) {
            const newCount = count + 1;
            setCount(newCount);
            if (onChange) {
                onChange(newCount);
            }
        }
    };

    const decrement = () => {
        if (count > min) {
            const newCount = count - 1;
            setCount(newCount);
            if (onChange) {
                onChange(newCount);
            }
        }
    };

    return (
        <div className="flex items-center justify-center px-2 py-1 rounded-lg">
            <button
                className={`flex items-center justify-center bg-gray-700 border-2 border-gray-500 text-gray-300 rounded-lg hover:bg-gray-600 ${
                    count === min ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                onClick={decrement}
                disabled={count === min}
            >
                <MinusOutlined style={{fontSize: '24px'}}/>
            </button>

            <div className="text-xl text-gray-300 mx-1 w-8 flex items-center justify-center bg-gray-700 rounded-lg">
                {count}
            </div>

            <button
                className={`flex items-center justify-center bg-gray-700 border-2 border-gray-500 text-gray-300 rounded-lg hover:bg-gray-600 ${
                    count === max ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                onClick={increment}
                disabled={count === max}
            >
                <PlusOutlined style={{fontSize: '24px'}}/>
            </button>
        </div>
    );
};

export default Counter;
