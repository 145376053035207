import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal} from 'antd';
import 'firebase/firestore';
import {collection, doc, getDocs, query, updateDoc, where} from 'firebase/firestore';
import {db} from '../config/firebaseConfig';


const InvitationModal = ({playerData, logout, user}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inviteCode, setInviteCode] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        setIsModalVisible(true);
        if (playerData && !playerData.isRegistered) {
            setIsModalVisible(true);
        }
    }, [playerData]);

    const handleOk = async () => {
        try {
            const q = query(
                collection(db, 'inviteCodes'),
                where('inviteCode', '==', inviteCode),
                where('isUsed', '==', false)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const inviteDoc = querySnapshot.docs[0];
                if (playerData) {
                    const userRef = doc(db, 'users', user.id);
                    const expirationDate = new Date();
                    expirationDate.setUTCDate(expirationDate.getUTCDate() + 7);
                    await updateDoc(userRef, {
                        isRegistered: true,
                        inviteCode: inviteCode,
                        name: username,
                        expirationDate: expirationDate
                    });
                    const inviteRef = doc(db, 'inviteCodes', inviteDoc.id);
                    if ('rps-1024' !== inviteCode) {
                        await updateDoc(inviteRef, {
                            isUsed: true,
                        });
                    }
                    message.success('Registration successful');
                    setIsModalVisible(false);
                }
            } else {
                message.error('Invalid or already used invitation code, please try again');
            }
        } catch (error) {
            console.error("Error during registration: ", error);
            message.error('An error occurred during registration, please try again later.');
        }
    };

    const handleCancel = () => {
        logout();
        setIsModalVisible(false);
    };

    const handleInputChange = (e) => {
        setInviteCode(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleOk();
        }
    };


    if (!playerData || playerData.inviteCode === 'rps-1024') {
        return null;
    }

    if (playerData.isRegistered &&
        playerData.expirationDate &&
        playerData.expirationDate.toDate().getTime() > new Date().getTime()) {
        return null;
    }

    return (
        <>
            <Modal
                title="Invitation Registration"
                visible={isModalVisible}
                onCancel={handleCancel}
                maskClosable={false}
                wrapClassName="backdrop-blur"
                footer={null}>
                <>
                    <p className="mb-4">Our game is currently invite-only.</p>
                    <div>You can follow our community to get an invitation code.</div>
                    <div>
                        Join us on{' '}
                        <a
                            href="https://discord.gg/HHyH6epY"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-purple-600 hover:text-purple-800"
                        >
                            Discord
                        </a>
                        .
                    </div>
                    <Input
                        className="mt-4"
                        placeholder="Enter your invite code"
                        value={inviteCode}
                        onKeyPress={handleKeyPress}
                        onChange={handleInputChange}
                    />
                    <Input
                        className="mt-4"
                        placeholder="Enter your username"
                        value={username}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <div className="mt-4 flex justify-end">
                        <Button key="back" className="mr-4" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleOk}
                            className="bg-gradient-to-r from-purple-500 to-purple-700 hover:from-purple-700 hover:to-purple-500 text-white"
                        >
                            Register
                        </Button>
                    </div>
                </>
            </Modal>
        </>
    );
};

export default InvitationModal;
