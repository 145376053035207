import React, {useState} from 'react';

const GameRules = ({onClose}) => {
    const [currentPage, setCurrentPage] = useState(1);

    const renderPage = () => {
        switch (currentPage) {
            case 1:
                return (
                    <div className="bg-black bg-opacity-50 p-6 rounded-lg mb-6">
                        <h2 className="text-3xl font-bold mb-6 text-cyan-300">🎮 How to Play</h2>
                        <ol className="list-decimal list-inside text-lg space-y-4">
                            <li>Buy Rock (🪨), Paper (📄), or Scissors (✂️) tokens</li>
                            <li>Deploy your tokens on the game canvas</li>
                            <li>Watch the simulation unfold</li>
                            <li>Win based on your tokens' performance</li>
                        </ol>
                        <h2 className="text-3xl font-bold mt-8 mb-6 text-green-300">💡 Game Rules</h2>
                        <ul className="list-disc list-inside text-lg space-y-4">
                            <li>🪨 Rock beats ✂️ Scissors</li>
                            <li>✂️ Scissors beats 📄 Paper</li>
                            <li>📄 Paper beats 🪨 Rock</li>
                        </ul>
                    </div>
                );
            case 2:
                return (
                    <div className="bg-black bg-opacity-50 p-6 rounded-lg mb-6">
                        <h2 className="text-3xl font-bold mb-6 text-yellow-300">💰 Payout Calculation</h2>
                        <p className="text-lg mb-6">Your payout is based on the proportion of winning tokens you
                            own:</p>
                        <ol className="list-decimal list-inside text-lg space-y-4">
                            <li>Calculate your share: (Your winning tokens) / (Total winning tokens)</li>
                            <li>Multiply your share by the total number of each token type</li>
                            <li>Claim your fractional share of the items</li>
                        </ol>
                        <div className="bg-gray-800 p-6 rounded-lg mt-8">
                            <p className="text-xl font-semibold mb-2">Example:</p>
                            <p className="text-lg">If you own 2 out of 30 winning Rock tokens, you get 1/15 of all
                                items.</p>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="bg-black bg-opacity-50 p-6 rounded-lg mb-6">
                        <h2 className="text-3xl font-bold mb-6 text-center text-yellow-300">🎮 Game Example 🎮</h2>

                        <div className="grid grid-cols-2 gap-6 mb-6">
                            <div>
                                <h3 className="text-2xl font-bold mb-4 text-cyan-300">🌈 Total Entries:</h3>
                                <div className="flex flex-col space-y-2 text-xl">
                                    <div>🪨 Rock: 30</div>
                                    <div>📄 Paper: 20</div>
                                    <div>✂️ Scissors: 25</div>
                                </div>
                            </div>

                            <div>
                                <h3 className="text-2xl font-bold mb-4 text-green-300">💎 You Entered:</h3>
                                <div className="flex flex-col space-y-2 text-xl">
                                    <div>🪨 Rock: 2</div>
                                    <div>📄 Paper: 1</div>
                                    <div>✂️ Scissors: 3</div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3 className="text-2xl font-bold mb-4 text-pink-300">🤑 Result:</h3>
                            <p className="text-xl mb-2">🪨 If Rock wins!</p>
                            <div className="text-lg">
                                <p>You have 2 🪨 out of 30 total 🪨</p>
                                <p>Your share: 2/30 = 1/15 of all items</p>
                                <p className="font-semibold mt-4 mb-2">✨ You win:</p>
                                <ul className="list-disc list-inside ml-4 space-y-1">
                                    <li>🪨 2 (1/15 of 30)</li>
                                    <li>📄 1.33 (1/15 of 20)</li>
                                    <li>✂️ 1.67 (1/15 of 25)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/90 backdrop-blur-sm">
            <div className="bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 p-6 sm:p-10 rounded-2xl shadow-2xl border border-purple-500/30 w-full sm:w-[650px] h-[90vh] sm:h-[750px] relative animate-fade-in">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-400 hover:text-white transition-all duration-300 transform hover:scale-110"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 sm:h-8 sm:w-8" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>

                <div className="overflow-y-auto h-[calc(100%-80px)] custom-scrollbar pr-4 mb-6">
                    {renderPage()}
                </div>

                <div className="flex justify-between mt-6 px-2">
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                        className={`
                            flex items-center px-6 py-3 rounded-xl font-bold
                            ${currentPage === 1 
                                ? 'bg-gray-800 text-gray-500 cursor-not-allowed' 
                                : 'bg-gradient-to-r from-blue-600 to-cyan-600 text-white hover:from-blue-500 hover:to-cyan-500 transform hover:scale-105 hover:shadow-lg hover:shadow-blue-500/25'
                            }
                            transition-all duration-300 text-sm sm:text-base
                        `}
                        disabled={currentPage === 1}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                        Previous
                    </button>

                    <button
                        onClick={() => setCurrentPage(prev => Math.min(3, prev + 1))}
                        className={`
                            flex items-center px-6 py-3 rounded-xl font-bold
                            ${currentPage === 3
                                ? 'bg-gray-800 text-gray-500 cursor-not-allowed'
                                : 'bg-gradient-to-r from-purple-600 to-pink-600 text-white hover:from-purple-500 hover:to-pink-500 transform hover:scale-105 hover:shadow-lg hover:shadow-purple-500/25'
                            }
                            transition-all duration-300 text-sm sm:text-base
                        `}
                        disabled={currentPage === 3}
                    >
                        Next
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GameRules;
