import React from 'react';
import {ref, set} from 'firebase/database';
import {rdb} from '../config/firebaseConfig';

const LiveStreamWindow = ({lobbyState, setLobbyState, roomId}) => {
    const updateLobbyState = (newLiveLink: string | null) => {
        const updatedLobbyState = {...lobbyState, liveLink: newLiveLink, isLive: !!newLiveLink};
        console.log("updatedLobbyState", updatedLobbyState);
        const lobbyStateRef = ref(rdb, `rooms/${roomId}/lobbyState`);
        set(lobbyStateRef, updatedLobbyState);
        setLobbyState(updatedLobbyState);
    };

    return (
        <div className="grid grid-rows-1 grid-cols-1 h-1/5 bg-spotify-black rounded-lg overflow-hidden">
            {lobbyState.liveLink ? (
                <div className="relative w-full h-full">
                    <iframe
                        src={`https://www.youtube.com/embed/${getYouTubeVideoId(lobbyState.liveLink)}?autoplay=1`}
                        className="w-full h-full rounded-lg"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    ></iframe>
                    <button
                        className="absolute top-2 right-2 bg-spotify-green hover:bg-spotify-green-light text-white font-bold py-1 px-3 rounded-full text-xs transition-all duration-300 transform hover:scale-105"
                        onClick={() => updateLobbyState(null)}>
                        Change Stream
                    </button>
                </div>
            ) : (
                <div className="w-full h-full flex flex-col items-center justify-center bg-spotify-dark-gray rounded-lg p-6">
                    <input
                        type="text"
                        placeholder="Enter any YouTube live link"
                        className="w-full p-3 mb-4 bg-spotify-light-gray text-black border-none rounded-full focus:outline-none focus:ring-2 focus:ring-spotify-green transition-all duration-300"
                        defaultValue={lobbyState.liveLink || ''}
                        id="youtubeLiveLink"
                    />
                    <button
                        className="w-full bg-spotify-green hover:bg-spotify-green-light text-white font-bold py-3 px-6 rounded-full transition-all duration-300 transform hover:scale-105"
                        onClick={() => {
                            const inputElement = document.getElementById('youtubeLiveLink') as HTMLInputElement;
                            updateLobbyState(inputElement.value);
                        }}
                    >
                        Start Streaming
                    </button>
                </div>
            )}
        </div>
    );
};


const getYouTubeVideoId = (url: string): string | null => {
    try {
        const urlObj = new URL(url);
        const hostname = urlObj.hostname.toLowerCase();

        if (hostname === 'youtu.be') {
            const videoId = urlObj.pathname.slice(1);
            return videoId.length === 11 ? videoId : null;
        }

        if (hostname === 'www.youtube.com' || hostname === 'youtube.com') {
            const pathname = urlObj.pathname;

            if (pathname === '/watch') {
                const videoId = urlObj.searchParams.get('v');
                return videoId && videoId.length === 11 ? videoId : null;
            }

            if (pathname.startsWith('/embed/')) {
                const videoId = pathname.split('/embed/')[1];
                return videoId && videoId.length === 11 ? videoId : null;
            }

            if (pathname.startsWith('/v/')) {
                const videoId = pathname.split('/v/')[1];
                return videoId && videoId.length === 11 ? videoId : null;
            }

            if (pathname.startsWith('/live/')) {
                const videoId = pathname.split('/live/')[1];
                return videoId && videoId.length === 11 ? videoId : null;
            }
        }

        return null;
    } catch (e) {
        console.error('Invalid URL:', e);
        return null;
    }
};

export default LiveStreamWindow;
