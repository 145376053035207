import React, {useState, useEffect} from 'react';
import {db} from '../config/firebaseConfig';
import {collection, getDocs, query, orderBy, limit} from 'firebase/firestore';
import useMarket from "../hooks/useMarket";

interface LeaderboardEntry {
    id: string;
    name: string;
    netWorth: number;
}

const GameLeaderboard: React.FC<{currentUserId?: string}> = ({currentUserId}) => {
    const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
    const [loading, setLoading] = useState(true);
    const {itemPrices} = useMarket(null); // Get current item prices

    useEffect(() => {
        const fetchLeaderboard = async () => {
            try {
                const usersRef = collection(db, 'users');
                const q = query(usersRef);
                const querySnapshot = await getDocs(q);

                const leaderboardData: LeaderboardEntry[] = querySnapshot.docs.map(doc => {
                    const userData = doc.data();
                    const inventory = userData.inventory || {};
                    const money = userData.money || 0;

                    // Calculate net worth
                    const inventoryWorth = Object.entries(inventory).reduce((total, [item, quantity]) => {
                        const itemPrice = itemPrices[item as keyof typeof itemPrices]?.price || 0;
                        return total + itemPrice * (quantity as number);
                    }, 0);
                    const netWorth = inventoryWorth + money;

                    return {
                        id: doc.id,
                        name: userData.name || 'Anonymous',
                        netWorth: netWorth,
                    };
                });

                // Sort by net worth and take top 10
                leaderboardData.sort((a, b) => b.netWorth - a.netWorth);
                setLeaderboard(leaderboardData.slice(0, 100));
                setLoading(false);
            } catch (error) {
                console.error("Error fetching leaderboard:", error);
                setLoading(false);
            }
        };

        fetchLeaderboard();
    }, [itemPrices]);

    if (loading) {
        return <div className="text-center py-4">Loading leaderboard...</div>;
    }

    return (
        <div className="bg-gradient-to-br from-gray-900 via-purple-900 to-gray-900 p-4 sm:p-6 rounded-2xl shadow-2xl border border-purple-500/20">
            <h2 className="text-2xl sm:text-4xl font-black mb-4 sm:mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 text-center">
                👑 Leaderboard 👑
            </h2>
            <div className="overflow-x-auto">
                <table className="w-full text-sm sm:text-md text-left">
                    <thead className="text-base sm:text-lg uppercase bg-black/40 text-purple-300">
                    <tr>
                        <th scope="col" className="px-2 sm:px-6 py-2 sm:py-4 font-black">Rank</th>
                        <th scope="col" className="px-2 sm:px-6 py-2 sm:py-4 font-black">Name</th>
                        <th scope="col" className="px-2 sm:px-6 py-2 sm:py-4 font-black">Net Worth 💎</th>
                    </tr>
                    </thead>
                    <tbody>
                    {leaderboard.map((entry, index) => (
                        <tr key={entry.id}
                            className={`border-b border-purple-500/20 transition-all duration-300 ease-in-out ${
                                entry.id === currentUserId 
                                    ? 'bg-purple-900/40 hover:bg-purple-900/60' 
                                    : 'bg-black/20 hover:bg-purple-900/20'
                            }`}>
                            <th scope="row"
                                className={`px-2 sm:px-6 py-2 sm:py-4 font-black whitespace-nowrap ${
                                    index < 3 ? 'text-yellow-400' : 'text-gray-400'
                                }`}>
                                {index < 3 ? ['👑', '🥈', '🥉'][index] : `#${index + 1}`}
                            </th>
                            <td className={`px-2 sm:px-6 py-2 sm:py-4 font-bold ${
                                entry.id === currentUserId ? 'text-purple-300' : 'text-purple-200'
                            }`}>
                                {entry.name}
                                {entry.id === currentUserId && ' (You)'}
                            </td>
                            <td className="px-2 sm:px-6 py-2 sm:py-4 font-black text-cyan-400">
                                <span className="inline-block mr-1">💎</span>
                                ${entry.netWorth.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default GameLeaderboard;
