import React, {useEffect} from 'react';
import useSound from 'use-sound';

interface CountdownProps {
    targetGameStartTime: number | null;
    timeLeft: number;
    setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
}

const Countdown: React.FC<CountdownProps> = ({targetGameStartTime, timeLeft, setTimeLeft}) => {
    const [playPaperRockSound] = useSound('/assets/countdown.mp3', {volume: 0.2});

    useEffect(() => {
        if (targetGameStartTime === null) {
            setTimeLeft(0);
            return;
        }

        const calculateTimeLeft = () => {
            const difference = targetGameStartTime - Date.now();
            return Math.max(0, Math.floor(difference / 1000));
        };

        setTimeLeft(calculateTimeLeft());

        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);

            if (newTimeLeft === 6) {
                console.log('playing sound for countdown');
                playPaperRockSound();
            }

            if (newTimeLeft <= 0) {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [targetGameStartTime, playPaperRockSound]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    if (targetGameStartTime === null) {
        return <div className="text-red-500 text-lg sm:text-xl font-mono font-bold">🔥WAITING START!🔥</div>
    }

    if (timeLeft > 0) {
        return (
            <div className="text-red-500">
                <div className="text-2xl sm:text-3xl font-mono font-bold">
                    🔥{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}🔥
                </div>
            </div>
        );
    }
    if (timeLeft <= 0) {
        return (
            <div className="text-red-500">
                <div className="text-lg sm:text-xl font-mono font-bold">
                    🔥GAME STARTED!🔥
                </div>
            </div>
        );
    }
    return null;
};

export default Countdown;
