import React, {useEffect, useState} from 'react';
import {usePrivy} from '@privy-io/react-auth';
import {off, onValue, query as rQuery, ref} from "firebase/database";
import {rdb} from '../config/firebaseConfig';
import {GameData} from '../context/typeDefinitions';
import {Utils} from '../utils/utils';

const GameHistoryWidget = ({playerNames, roomId, lobbyState}) => {
  const {user} = usePrivy();
  const [completedGames, setCompletedGames] = useState<GameData[]>([]);

  useEffect(() => {
    const gamesRef = ref(rdb, `rooms/${roomId}/games`);
    const sortedGamesRef = rQuery(gamesRef);

    const unsubscribe = onValue(sortedGamesRef, (snapshot) => {
      const games: GameData[] = [];
      snapshot.forEach((childSnapshot) => {
        const gameData = childSnapshot.val() as GameData;
        if (gameData.status === 'completed') {
          games.push({
            ...gameData,
            id: childSnapshot.key
          });
        }
      });
      games.sort((a, b) => b.startTime - a.startTime);
      setCompletedGames(games);
    }, (error) => {
      console.error("Error listening to games:", error);
    });

    return () => {
      off(sortedGamesRef);
      unsubscribe();
    };
  }, []);

  const getPlayerDisplayName = (playerId: string) => {
    if (playerId === user?.id) return 'You';
    return playerNames[playerId] || playerId;
  };

  return (
      <div className="bg-black text-green-400 p-4 rounded-xl">
        <h2 className="text-2xl font-bold mb-4 text-green-300">🎮 Rewards History 🎵</h2>
        <div className="bg-gray-900 p-4 rounded-xl max-h-[600px] overflow-y-auto custom-scrollbar">
          {completedGames.map((game, index) => (
              <div key={index}
                   className="mb-4 p-3 bg-gray-800 rounded-lg shadow-neon hover:shadow-neon-intense transition-all duration-300">
                <div className="text-xs text-gray-400 mb-1">
                  {game.startTime && new Date(game.startTime).toLocaleString()}
                </div>
                <div className="font-extrabold text-lg mb-2">
                  🏆 Winner: {game.winningType} {Utils.getSymbol(game.winningType ?? '', lobbyState.symbols)}
                </div>
                <div className="text-yellow-400 font-semibold">Pot: 💰 ${game.pot.toFixed(2)}</div>
                {user?.id && game.playerEntries && game.playerEntries[user.id] ? (
                    <div className="text-blue-400 font-semibold mt-1">
                      Your Entries:
                      {Object.entries(game.playerEntries[user.id]).map(([itemType, count]) => {
                        if (itemType === 'itemCoordinates') {
                          return null;
                        }
                        return (
                            <span key={itemType} className="ml-2">
                    {Utils.getSymbol(itemType, lobbyState.symbols)} x {count}
                  </span>
                        )
                      })}
                    </div>
                ) : user?.id ? (
                    <div className="text-gray-400 font-semibold mt-1">
                      You did not participate in this game.
                    </div>
                ) : null}
                {game.rewards && (
                    <div className="mt-2 text-sm">
                      <strong className="text-pink-400">Rewards:</strong>
                      <ul className="mt-1 space-y-1">
                        {Object.entries(game.rewards).map(([playerId, reward]) => (
                            <li key={playerId} className="flex items-center flex-wrap">
                      <span className="mr-2">
                        {playerId === user?.id ? '😎 You' : `👤 ${getPlayerDisplayName(playerId)}`}
                      </span>
                              <div className="flex flex-wrap w-full">
                                {Object.entries(reward).map(([itemType, count]) => {
                                  const originalCount = game.playerEntries?.[playerId]?.[itemType] || 0;
                                  const pnl = count;
                                  const changeColor = pnl >= 0 ? 'text-green-400' : 'text-red-400';
                                  return (
                                      <span key={itemType}
                                            className="mr-2 mb-1 bg-gray-700 px-2 py-1 rounded-full text-xs flex items-center">
                              {Utils.getSymbol(itemType, lobbyState.symbols)} x {originalCount.toFixed(2)}
                                        <span className={`ml-1 ${changeColor}`}>
                                ({pnl >= 0 ? '+' : ''}{pnl.toFixed(2)})
                              </span>
                            </span>
                                  );
                                })}
                              </div>
                            </li>
                        ))}
                      </ul>
                    </div>
                )}
              </div>
          ))}
        </div>
      </div>
  );
};

export default GameHistoryWidget;
