import React, {useEffect, useState} from 'react';
import GameCanvas from './GameCanvas';
import {Utils} from '../utils/utils';
import {usePrivy} from '@privy-io/react-auth';
import {Divider} from 'antd';
import {PlayerEntry} from '../context/typeDefinitions';
import {Loader} from 'lucide-react';
import Counter from "./InputNumber";

const items: string[] = ['Rock', 'Paper', 'Scissors'];

const GameInterface = ({
                           gameState,
                           animatedItems,
                           isSimulating,
                           setAnimatedItems,
                           distributeRewards,
                           targetGameStartTime,
                           placeOnWallByRdb,
                           lobbyState,
                           playerNames,
                           simulateGameByRdb,
                           playerData,
                           currentGameId,
                           isDeploying,
                           itemPrices,
                           canvasRef,
                           deployRef,
                           roomId,
                           isTrade,
                           onBuy,
                           onSell,
                       }) => {
    const {user} = usePrivy();
    const [timeLeft, setTimeLeft] = useState(0);
    const [isMuted, setIsMuted] = useState(false);
    const [totalTime, setTotalTime] = useState(0);
    const [percentage, setPercentage] = useState(100);

    useEffect(() => {
        const startTime = new Date().getTime();
        const initialTotalTime = (targetGameStartTime - startTime) / 1000;
        setTotalTime(initialTotalTime);
    }, [targetGameStartTime]);

    useEffect(() => {
        if (totalTime > 0) {
            const newPercentage = Math.max(0, Math.min(100, (timeLeft / totalTime) * 100));
            setPercentage(newPercentage);
        }
    }, [timeLeft, totalTime]);

    const [itemsAddCounts, setItemsAddCounts] = React.useState({});
    const handleItemsAddCountChange = (item, value) => {
        setItemsAddCounts(prevCounts => ({
            ...prevCounts,
            [item]: value,
        }));
    };

    const [itemsReduceCounts, setItemsReduceCounts] = React.useState({});
    const handleItemsReduceCountChange = (item, value) => {
        setItemsReduceCounts(prevCounts => ({
            ...prevCounts,
            [item]: value,
        }));
    };

    return (
        <div className="w-full flex flex-col items-center px-2 md:px-0">
            {/* Container for the canvas and other components */}
            <div className="w-full max-w-screen-md">
                {/* Game Header */}
                <div ref={canvasRef} className="w-full">
                    <GameCanvas
                        gameState={gameState}
                        animatedItems={animatedItems}
                        isSimulating={isSimulating}
                        setAnimatedItems={setAnimatedItems}
                        onSimulationEnd={distributeRewards}
                        startTime={targetGameStartTime}
                        onSetItem={placeOnWallByRdb}
                        itemPrices={itemPrices}
                        isMuted={isMuted}
                        timeLeft={timeLeft}
                        setTimeLeft={setTimeLeft}
                        simulateGameByRdb={simulateGameByRdb}
                        lobbyState={lobbyState}
                    />
                </div>

                {/* Double Rewards Notification */}
                {lobbyState.mapMetadata.type !== 'Empty' && (
                    <div
                        className="text-sm sm:text-base md:text-xl font-bold text-white bg-[#E09C9C] p-2 mb-0 w-full flex flex-wrap items-center justify-center">
                        <span className="mr-1 sm:mr-2 text-white">📣📣📣:</span>
                        <span className="text-white">All {Utils.getSymbol(lobbyState.mapMetadata.type)}</span>
                        <span className="inline-block mx-1 sm:mx-2 font-extrabold underline text-white">DOUBLE</span>
                        <span className="text-white">for next</span>
                        <span className="ml-1 text-white">{lobbyState.mapMetadata.rounds} rounds</span>
                    </div>
                )}

                {/* Game Stats */}
                <div className="bg-black text-white p-2 mt-2 rounded-lg flex flex-row items-center justify-between">
                    <div className="flex items-center">
                        <button
                            onClick={() => setIsMuted(!isMuted)}
                            className="bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full p-1 transition-all duration-300 mr-1"
                        >
                            {isMuted ? '🔇' : '🔊'}
                        </button>
                        <h2 className="text-sm font-bold">Stats</h2>
                    </div>
                    <div className="flex justify-center">
                        {['Rock', 'Paper', 'Scissors'].map(item => {
                            // @ts-ignore
                            const totalItems = Object.values(gameState.playerEntries || {}).reduce((sum, entry) => sum + (entry[item] || 0), 0);
                            const totalAllItems = Object.values(gameState.playerEntries || {}).reduce((sum, entry) =>
                                // @ts-ignore
                                sum + (entry['Rock'] || 0) + (entry['Paper'] || 0) + (entry['Scissors'] || 0), 0);
                            // @ts-ignore
                            const percentage = totalAllItems > 0 ? (totalItems / totalAllItems * 100).toFixed(1) : '0.0';
                            return (
                                <div key={item} className="flex items-center mr-2">
                                    <div className="text-lg mr-1">{Utils.getSymbol(item)}</div>
                                    <div className="w-12 bg-gray-700 rounded-full h-2 mr-1"
                                         style={{overflow: 'hidden'}}>
                                        <div className="bg-green-500 h-2 rounded-full"
                                             style={{width: `${percentage}%`}}></div>
                                    </div>
                                    <div className="text-xs">{percentage}%</div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="bg-[#1F2937] p-4 mt-2 rounded-lg flex flex-col md:flex-col items-center">
                    <div className="w-[calc(100%+2rem)] bg-[#2E3949] -mx-4 -mt-4 mb-6 rounded-lg">
                        <div className="flex-1 mb-4 md:mb-0 hidden md:block">
                            <div
                                className="text-cyan-300 text-center text-base md:text-2xl mb-1 font-bold hidden md:block">
                                PLACE YOUR BETS
                            </div>
                            <div className="text-gray-400 text-center text-sm md:text-base font-bold hidden md:block">
                                Auto layout with the buttons or click the canvas to deploy
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 w-full">
                        <div className="flex justify-around flex-wrap" ref={deployRef}>
                            {playerData && playerData.inventories && playerData.inventories[roomId] ? (
                                items.map((item, index) => (
                                    <div key={item}
                                         className={`flex flex-col items-center mb-4 md:mb-0 w-1/3 ${index < items.length - 1 ?
                                             'border-r border-gray-300' : ''}`}>
                                        <button
                                            onClick={() => placeOnWallByRdb(item)}
                                            disabled={
                                                playerData?.inventories[roomId][item] === 0 ||
                                                isSimulating ||
                                                !currentGameId ||
                                                isDeploying
                                            }
                                            className="relative bg-blue-600 px-3 py-2 rounded-lg text-white font-bold disabled:opacity-50
                        flex flex-col items-center overflow-hidden"
                                            style={{
                                                boxShadow: '0 0 15px 5px rgba(59, 130, 246, 0.5)',
                                                transition: 'all 0.3s ease',
                                                border: '4px solid #00FFFF',
                                                borderRadius: '16px',
                                            }}>
                                            <div
                                                className="absolute inset-0 bg-blue-400 opacity-30 animate-pulse"></div>
                                            <span className="text-2xl md:text-5xl relative z-10">
                        {Utils.getSymbol(item, lobbyState.symbols)}
                      </span>
                                            <div
                                                className="absolute inset-0 bg-gradient-to-br from-blue-300 to-blue-600 opacity-50"></div>
                                        </button>
                                        <div className="mt-2 text-center">
                      <span className="bg-indigo-200 bg-opacity-20 p-1 md:p-2 pb-1 pt-2 rounded-full shadow-lg">
                        <span
                            className="text-xs md:text-sm font-bold text-cyan-300"
                            style={{textShadow: '0 0 8px rgba(80, 239, 255, 0.8)'}}>
                          {(playerData?.inventories[roomId][item] ?? 0).toFixed(2)}
                        </span>
                      </span>
                                            <span className="text-xs md:text-sm text-blue-100"> left</span></div>
                                        <div key={item} className="flex flex-col items-center rounded-lg p-1 mt-4 w-full md:w-auto">
                                            <div className="flex flex-col md:flex-row items-center justify-between px-2 md:px-4 w-full">
                                                <Counter
                                                    min={1}
                                                    max={100}
                                                    onChange={(value) => handleItemsAddCountChange(item, value)}
                                                />
                                                <button
                                                    disabled={isTrade}
                                                    onClick={() => onBuy(item, itemsAddCounts[item] || 1)}
                                                    className="bg-green-600 hover:bg-green-700 text-white px-2 py-1 md:px-4 md:py-1 rounded-full
                                                    text-sm font-medium w-full md:flex-grow mt-2 md:mt-0"
                                                    style={{transition: 'background-color 0.2s'}}
                                                >Buy
                                                </button>
                                            </div>
                                            <div className="flex flex-col md:flex-row items-center justify-between px-2 md:px-4 w-full mt-2">
                                                <Counter
                                                    min={1}
                                                    max={100}
                                                    onChange={(value) => handleItemsReduceCountChange(item, value)}
                                                />
                                                <button
                                                    disabled={isTrade}
                                                    onClick={() => onSell(item, itemsReduceCounts[item] || 1)}
                                                    className="bg-red-600 hover:bg-red-700 text-white px-2 py-1 md:px-4 md:py-1 rounded-full
                                                    text-sm font-medium w-full md:flex-grow mt-2 md:mt-0"
                                                    style={{transition: 'background-color 0.2s'}}
                                                >Sell
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <Loader/>
                            )}
                        </div>
                    </div>
                </div>

                {/* Player Stats */}
                <div className="bg-gray-700 rounded-lg p-4 mt-2 shadow-lg">
                    <h3 className="text-lg md:text-2xl font-bold text-white">Player Stats</h3>
                    <div className="text-sm md:text-base text-gray-300 mb-2">
                        {Object.keys(gameState.playerEntries).length || 0} players
                    </div>
                    <Divider className="mt-2 mb-4" style={{borderColor: '#CACFD5'}}/>
                    <div className="text-sm md:text-base max-h-40 md:max-h-56 overflow-y-auto pr-2 custom-scrollbar">
                        {Object.entries((gameState.playerEntries as Record<string, PlayerEntry>) || {})
                            .sort(([a], [b]) => {
                                if (a === user?.id) return -1;
                                if (b === user?.id) return 1;
                                return a.localeCompare(b);
                            })
                            .map(([playerId, playerEntry]) => (
                                <div
                                    key={playerId}
                                    className={`mb-2 flex flex-wrap items-center ${
                                        playerId === user?.id ? 'bg-indigo-800 rounded-lg p-2' : ''
                                    }`}
                                >
                                    <div
                                        className={`w-full md:w-40 flex-shrink-0 text-base md:text-lg font-semibold mb-2 md:mb-0 md:mr-4 ${
                                            playerId === user?.id ? 'text-green-300' : 'text-gray-300'
                                        }`}
                                    >
                                        {playerNames[playerId] || playerId}
                                    </div>
                                    <div
                                        className="w-full md:flex-grow flex flex-wrap md:flex-nowrap items-center bg-gray-800 rounded-full p-2 pl-4">
                                        {items.map((item) => (
                                            <div key={item} className="flex items-center mr-4 mb-2 md:mb-0">
                        <span
                            className={`text-sm md:text-base mr-2 ${
                                playerId === user?.id ? 'text-green-400' : 'text-gray-400'
                            }`}
                        >
                          {Utils.getSymbol(item, lobbyState.symbols)}
                        </span>
                                                <span className="text-lg md:text-xl font-medium text-white">
                          x {playerEntry[item] || 0}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameInterface;
