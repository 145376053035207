import React, {useState} from 'react';
import {Button, Checkbox, Input, message, Modal, Select} from 'antd';
import 'firebase/firestore';
import {addDoc, collection, getDocs} from 'firebase/firestore';
import {db} from '../config/firebaseConfig';

const {Option} = Select;

const WhitelistModal = ({isInviteFormVisible, setIsInviteFormVisible}) => {
    const [email, setEmail] = useState('');
    const [source, setSource] = useState('');
    const [isKol, setIsKol] = useState(false);
    const [platform, setPlatform] = useState('');
    const [platformNickname, setPlatformNickname] = useState('');

    const handleInviteFormSubmit = async () => {
        if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            message.error('Please fill in the correct e-mail, so that we can notify you!');
            return;
        }
        if (isKol && (!platform || !platformNickname)) {
            message.error('Please fill in all required fields');
            return;
        }

        const inviteData = {
            email: email,
            isKol: isKol,
            platform: isKol ? platform : null,
            platformNickname: isKol ? platformNickname : null,
            timestamp: new Date(),
        };

        try {
            await addDoc(collection(db, 'whitelist'), inviteData);
            const querySnapshot = await getDocs(collection(db, 'whitelist'));
            const inviteCount = 3000 + parseInt((querySnapshot.size * 10 + Math.random() * 10).toString());
            setIsInviteFormVisible(false);
            Modal.success(
                {
                    title: 'Congratulations！You’re In! 🎉',
                    content: `Current position: #${inviteCount}. You’re getting closer to unlocking RPS.live. Stay tuned.`,
                    okText: 'OK',
                    okButtonProps: {
                        style: {
                            backgroundColor: '#ec4899',
                            borderColor: '#ec4899',
                        },
                    },
                    onOk() {
                    },
                }
            );

        } catch (error) {
            message.error('Failed to submit application. Please try again.');
        }
    };


    return (
        <Modal
            title="Apply for Whitelist"
            visible={isInviteFormVisible}
            onCancel={() => setIsInviteFormVisible(false)}
            maskClosable={false}
            footer={[
                <Button key="cancel" onClick={() => setIsInviteFormVisible(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleInviteFormSubmit}>
                    Submit
                </Button>,
            ]}
        >
            <Input
                className="mb-4"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <Input
                className="mb-4"
                placeholder="Where did you hear about our game?"
                value={source}
                onChange={(e) => setSource(e.target.value)}
                required
            />
            <Checkbox
                checked={isKol}
                onChange={(e) => setIsKol(e.target.checked)}
            >
                Are you a social media KOL?
            </Checkbox>
            {isKol && (
                <>
                    <Input
                        className="mt-4"
                        placeholder="Enter your platform (e.g., YouTube, Instagram)"
                        value={platform}
                        onChange={(e) => setPlatform(e.target.value)}
                    />
                    <Input
                        className="mt-4"
                        placeholder="Enter your platform handle (e.g., @username)"
                        value={platformNickname}
                        onChange={(e) => setPlatformNickname(e.target.value)}
                    />
                </>
            )}
        </Modal>
    );
};

export default WhitelistModal;
