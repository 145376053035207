import {Button, Checkbox, Form, Input, Select, Spin, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import useMarket from "../hooks/useMarket";
import {get, ref, set} from "firebase/database";
import {db, rdb} from "../config/firebaseConfig";
import {v4 as uuidv4} from 'uuid';
import {collection, doc, getDocs, limit, orderBy, query, writeBatch} from "firebase/firestore";


const RoomCreate = ({closeModal}) => {
    const [form] = Form.useForm();
    const {initPricesAndSupplyByRdb, updateMarketData} = useMarket(null);
    const [rooms, setRooms] = useState<any[]>([]);
    const [filteredRooms, setFilteredRooms] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchRoomsFromFirebase = async () => {
        setLoading(true);
        try {
            const gamesRef = ref(rdb, 'rooms');
            const snapshot = await get(gamesRef);
            if (snapshot.exists()) {
                const data = snapshot.val();
                const roomsArray = Object.keys(data).map((key) => ({
                    id: key,
                    ...data[key],
                }));
                console.log('Rooms:', roomsArray);
                setRooms(roomsArray);
                setFilteredRooms(roomsArray);
            } else {
                console.log('No rooms found');
            }
        } catch (error) {
            console.error('Error fetching rooms:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchRoomsFromFirebase();
    }, []);

    const handleSearch = (value) => {
        if (value) {
            const filtered = rooms.filter((room) =>
                room.lobbyState.roomName.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredRooms(filtered);
        } else {
            setFilteredRooms(rooms);
        }
    };

    const generateRoomId = () => {
        return uuidv4().replace(/-/g, '');
    };

    async function initLobbyState(roomId: string, roomName, isPublic, itemPlacementToggle, liveLink, forkFrom) {
        const lobbyStateRef = ref(rdb, `rooms/${roomId}/lobbyState`);
        set(lobbyStateRef, {
            roomName: roomName,
            isPublic: isPublic,
            itemPlacementToggle: itemPlacementToggle,
            liveLink: liveLink || null,
            isLive: false,
            mapMetadata: {
                type: 'Empty',
                rounds: 0
            }
        });
        if (forkFrom && rooms.filter(e => e.id === forkFrom).length > 0) {
            const forkRoom = rooms.filter(e => e.id === forkFrom)[0]
            console.log('fork room:', forkRoom);
            // 拷贝一下价格
            updateMarketData(forkRoom?.market?.current, roomId);
            // 拷贝一下历史曲线
            const priceHistoryRef = collection(db, `rooms/${forkRoom.id}/priceHistory`);
            const priceHistoryQuery = query(priceHistoryRef, orderBy('timestamp', 'desc'), limit(200));
            try {
                const querySnapshot = await getDocs(priceHistoryQuery);
                if (!querySnapshot.empty) {
                    const batch = writeBatch(db);
                    querySnapshot.forEach((docSnapshot) => {
                        const data = docSnapshot.data();
                        const newPriceHistoryRef = doc(collection(db, `rooms/${roomId}/priceHistory`));

                        batch.set(newPriceHistoryRef, data);
                    });
                    await batch.commit();
                    console.log('copy price history success');
                } else {
                    console.log('price history is empty');
                }
            } catch (error) {
                console.error('copy price history error:', error);
            }
        }
    }

    const handleFormCancel = () => {
        closeModal();
        form.resetFields();
    }

    const handleFormSubmit = (values) => {
        const {roomName, isPublic, itemPlacementToggle, liveLink, forkFrom} = values;
        const roomId = generateRoomId();
        initPricesAndSupplyByRdb(roomId)
        initLobbyState(roomId, roomName, isPublic, itemPlacementToggle, liveLink, forkFrom);
        handleFormCancel();
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFormSubmit}>
                <Form.Item
                    label="Room Name"
                    name="roomName"
                    rules={[{required: true, message: 'Please enter the room name'}]}
                >
                    <Input placeholder="Enter room name"/>
                </Form.Item>

                <Form.Item
                    name="isPublic"
                    initialValue={true}
                    valuePropName="checked">
                    <Checkbox disabled={true}>Public Room</Checkbox>
                </Form.Item>

                <Form.Item
                    name="itemPlacementToggle"
                    valuePropName="checked"
                    initialValue={false}
                >
                    <Checkbox>Enable Item Placement Toggle</Checkbox>
                </Form.Item>

                <Form.Item
                    label="Live Stream Link"
                    name="liveLink"
                    rules={[{required: false, message: 'Please enter the live stream link'},
                        {type: 'url', message: 'Please enter a valid URL'}]}
                >
                    <Input placeholder="Enter live stream link"/>
                </Form.Item>
                <Form.Item
                    label={<Tooltip
                        title="You can use the items' prices from a selected room as the prices for the new room's items">
                        Fork From
                    </Tooltip>}
                    name="forkFrom">
                    <Select
                        showSearch
                        placeholder="You can select a room"
                        onSearch={handleSearch}
                        filterOption={false}
                        allowClear={true}
                        notFoundContent={loading ? <Spin size="small"/> : null}>
                        {filteredRooms.map(room => (
                            <Select.Option key={room.id} value={room.id}>
                                {room.lobbyState?.roomName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button
                        className="text-white px-2 py-2 rounded-full text-sm font-medium
                    flex-grow mr-2 disabled:opacity-50 disabled:cursor-not-allowed"
                        type="primary" htmlType="submit" block>
                        Create Room
                    </Button>
                    <Button
                        className="px-2 py-2 rounded-full text-sm
                                    font-medium flex-grow mr-2 disabled:opacity-50 disabled:cursor-not-allowed"
                        type="default" onClick={handleFormCancel} block style={{marginTop: '10px'}}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default RoomCreate;
