import React, {useEffect, useRef, useState} from 'react';
import Header from "../components/Header";
import InvitationModal from "../components/InvitationModal";
import RockPaperScissorsGame from "./RockPaperScissorsGame";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import LobbyHome from "./LobbyHome";
import {usePrivy} from "@privy-io/react-auth";
import {PlayerDataV2 as PlayerData} from "../context/typeDefinitions";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../config/firebaseConfig";
import IntroPage from "./IntroPage";
import {Loader} from "lucide-react";

const IndexPage = () => {
    const {login, logout, authenticated, ready, user} = usePrivy();
    const [playerData, setPlayerData] = useState<PlayerData | null>(null);
    const [showLeaderboard, setShowLeaderboard] = useState<boolean>(false);
    const ruleRef = useRef(null);

    useEffect(() => {
        const loadCurrentUser = async () => {
            if (!user || !user.id) return;
            const userRef = doc(db, 'users', user.id);
            const userDoc = await getDoc(userRef);
            let userData: PlayerData;
            if (userDoc.exists()) {
                userData = userDoc.data() as PlayerData;
                console.log("Loaded current user:", userData);
            } else {
                console.log("User document does not exist. Creating new user.");
                userData = {
                    inventories: {},
                    walletAddress: user.wallet?.address || '',
                    name: user.id,
                    money: 300
                };
                await setDoc(userRef, userData);
            }
            setPlayerData(userData);
        }
        if (user) {
            loadCurrentUser();
        }
    }, [user]);

    if (!ready) {
        return <div className="min-h-screen flex items-center justify-center"><Loader></Loader></div>;
    }

    if (!authenticated) {
        const newUrl = `${window.location.protocol}//${window.location.host}`;
        window.history.pushState({}, '', newUrl);
        return <IntroPage login={login}/>;
    }

    return (
        <Router>
            <div className="min-h-screen bg-[#111827]">
                {/* 注册模态框 */}
                <InvitationModal
                    playerData={playerData}
                    user={user}
                    logout={logout}
                />

                {/* 顶部导航栏 */}
                <div className="bg-white shadow-md">
                    <Header
                        ruleRef={ruleRef}
                        authenticated={authenticated}
                        playerData={playerData}
                        user={user}
                        logout={logout}
                        login={login}
                        setShowLeaderboard={setShowLeaderboard}
                        showLeaderboard={showLeaderboard}
                    />
                </div>

                {/* 主内容路由 */}
                <div>
                    <Routes>
                        <Route path="/" element={<LobbyHome playerData={playerData}/>}/>
                        <Route path="/:id" element={<RockPaperScissorsGame
                            playerData={playerData}
                            user={user}
                            ruleRef={ruleRef}
                            setPlayerData={setPlayerData}/>}/>
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default IndexPage;
