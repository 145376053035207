import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getDatabase} from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDNLRj6nxKs-YzRH3DL00s4q-IVxqd6bd4",
    authDomain: "crypto-bros-10ea6.firebaseapp.com",
    projectId: "crypto-bros-10ea6",  // Double-check this
    storageBucket: "crypto-bros-10ea6.appspot.com",
    messagingSenderId: "954067898723",
    appId: "1:954067898723:web:5b9f7667c660b94f050356",
    measurementId: "G-4YBBRFF0SP"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const rdb = getDatabase(app);

