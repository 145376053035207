import React, {useEffect, useRef, useState} from 'react';
import {EmojiProps} from '../context/typeDefinitions';
import WhitelistModal from "../components/WhitelistModal";

const EMOJIS = ['🎮', '🕹️', '🏆', '✊', '✋', '✌️',
    '🎯', '🏅', '🏹', '🏋️‍♂️', '🏄‍♀️', '🏇', '🏊‍♂️', '🏐', '🏀', '⚽',
    '⚾', '🎳', '🥋', '🥊', '🥇', '🥈', '🥉', '🎾', '🎱', '🛹',
    "💰", "🤩", "😶‍🌫️", "👀", "👻", "💩", "😈", "💀", "👽", "👾",
    "🤡", "🤖", "🎃", "🦄", "🍆", "🍌", "🍆", "😶‍🌫️", "🫨", "🦙",
    "😨", "🤢", "💩", "🤠", "👽", "🫶", "🫵", "🧏", "🐣", "🌱", "🍄", "🌙", "🌈", "☄️", "🍌", "🥐", "🥃", "🍷", "🧋", "💡",
    "💣", "💊", "🎈", "㊙️", "🆘", "🏳️‍🌈", "🌈", "🦹", "🧚", "🧛", "🧜", "🧝", "🧞", "🧟", "🥷", "🦸", "🦊", "🐉", "🌋", "🏔️",
    "🎭", "🎨", "🎼", "🕹️", "🧠", "👁️‍🗨️", "🗿", "🎡", "🎢", "🏰", "🌠", "🍭", "🍬", "🍫", "🍕", "🌮", "🥟", "🍱", "🍺", "🧃",
    "💎", "🔮", "🪄", "🎱", "🧲", "🧨", "🎉", "🎊", "🪅", "🪩", "🪬", "🔱", "🦇", "🕷️", "🦂", "🐙", "🦑", "🦠", "🧬", "🧪",
    "🧫", "🔬", "🔭", "📡", "💻", "🖨️", "🎥", "🎬", "🎧", "🎤", "🎹", "🪘", "🥁", "🪗", "🏆", "🏅", "🥇", "🎖️", "🏵️", "🎗️"
    , "🎫", "🎟️", "🎪", "🎭", "🎨", "🎰", "🎳", "🎯", "🎱", "🎮", "🎲", "🎴", "🃏", "🀄", "🎁", "🎈", "🎊", "🎉", "🎋", "🎍",
    '🚵', '🛹', '🛷', '🏂', '⛷️', '🤺', '🎣', '🎿', '🎽', '🎢',
    '🎡', '🎠', '🎪', '🎭', '🎨', '🎬', '🎤', '🎧', '🎼', '🎹'
];

const SPECIAL_EMOJIS = ['🪨', '📄', '✂️'];


class Emoji implements EmojiProps {
    x: number;
    y: number;
    speed: number;
    emoji: string;
    size: number;

    constructor(canvasWidth: number, canvasHeight: number) {
        this.x = Math.random() * canvasWidth;
        this.y = Math.random() * canvasHeight;
        this.speed = 1 + Math.random() * 3;
        this.emoji = Math.random() < 0.1 ?
            SPECIAL_EMOJIS[Math.floor(Math.random() * SPECIAL_EMOJIS.length)] :
            EMOJIS[Math.floor(Math.random() * EMOJIS.length)];
        this.size = 20 + Math.random() * 20;
    }

    draw(ctx: CanvasRenderingContext2D) {
        ctx.font = `${this.size}px Arial`;
        ctx.fillText(this.emoji, this.x, this.y);
    }

    update(canvasHeight: number, canvasWidth: number) {
        this.y += this.speed;
        if (this.y > canvasHeight) {
            this.y = 0;
            this.x = Math.random() * canvasWidth;
        }
    }
}

const FallingEmojis: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [dimensions, setDimensions] = useState({width: window.innerWidth, height: window.innerHeight * 0.5});

    useEffect(() => {
        const handleResize = () => {
            setDimensions({width: window.innerWidth, height: window.innerHeight * 0.5});
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        let animationFrameId: number;

        canvas.width = dimensions.width;
        canvas.height = dimensions.height;

        // Increase the number of emojis for higher density
        const emojis = Array(100).fill(null).map(() => new Emoji(canvas.width, canvas.height));

        const animate = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            emojis.forEach(emoji => {
                emoji.update(canvas.height, canvas.width);
                emoji.draw(ctx);
            });
            animationFrameId = requestAnimationFrame(animate);
        };

        animate();

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [dimensions]);

    return <canvas ref={canvasRef} className="absolute top-0 left-0"/>;
};

interface IntroPageProps {
    login: () => void;
}

const IntroPage: React.FC<IntroPageProps> = ({login}) => {
    const [isInviteFormVisible, setIsInviteFormVisible] = useState(false);
    return (
        <>
            <WhitelistModal isInviteFormVisible={isInviteFormVisible}
                            setIsInviteFormVisible={setIsInviteFormVisible}></WhitelistModal>
            <div className="relative w-full h-screen overflow-hidden bg-[#181A1E] flex flex-col">
                <div className="flex-1 relative">
                    <FallingEmojis/>
                    <div className="absolute inset-0 flex flex-col items-center justify-center">
                        <h1 className="text-6xl font-medium text-white"
                            style={{
                                fontFamily: 'Tektur',
                                textShadow: '-1.92px 0px 0px #14D6EF, 1.92px 0px 0px #792CFD'
                            }}>
                            RPS.LIVE
                        </h1>
                        <span
                            className="font-tektur font-semibold text-[16px] leading-[21px] tracking-[0.12em] text-[#DADADA] mt-1"
                            style={{fontFamily: 'Tektur'}}
                        >Social Money Streaming Platform</span>
                    </div>
                </div>

                <div className="flex-1 flex flex-col items-center justify-center p-8">
                <span
                    className="font-inter font-normal text-[32px] leading-[39px] text-transparent bg-clip-text"
                    style={{
                        background: 'linear-gradient(180deg, #FFFFFF 0%, #64646C 100%)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        backgroundClip: 'text',
                    }}>Limited Invites Available. Will You Be Next?</span>

                    <div className="text-center p-6 mt-6">
                        <button
                            onClick={() => setIsInviteFormVisible(true)}
                            style={{
                                borderImageSource: 'linear-gradient(90deg, #5CEDFD 0%, #DF73FA 100%)',
                                borderImageSlice: 1,
                                borderWidth: '4px',
                                borderStyle: 'solid',
                                borderRadius: '4px',
                            }}
                            className="bg-[#3C4553] group-hover:duration-200 transition text-white
     py-4 px-10 animate-tilt duration-300 hover:shadow-xl transform hover:scale-105 mb-8 text-xl">
                            Claim Your Spot
                        </button>
                        <div className="mt-10">
                            <h2 className="text-[16px] text-white mb-2">or</h2>
                            <h2 className="text-[16px] text-white underline cursor-pointer" onClick={login}>I already
                                have access</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntroPage;
