import React, {useState} from 'react';
import {Edit, HomeIcon, Info, LogOut, ShareIcon, Trophy, User} from 'lucide-react';
import GameLeaderboard from './GameLeaderboard';
import {doc, updateDoc} from "firebase/firestore";
import {db} from '../config/firebaseConfig';
import GameRules from './GameRules';
import {message} from "antd";
import {useNavigate} from "react-router-dom";


const Header = ({ruleRef, authenticated, playerData, user, logout, login, setShowLeaderboard, showLeaderboard}) => {
    const [showUsernamePopup, setShowUsernamePopup] = useState(false);
    const [usernameInput, setUsernameInput] = useState('');
    const [showRulesPopup, setShowRulesPopup] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const navigate = useNavigate();

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    return (
        <div
            className="w-full px-4 py-2 flex flex-wrap justify-between items-center bg-gradient-to-r from-purple-900 via-indigo-900 to-blue-900 text-white shadow-lg">
            <div className="flex items-center">
                <img onClick={() => navigate('/')} src="/rps-logo.png" alt="RPS.live Logo"
                     className="h-8 w-8 mr-2 cursor-pointer"/>
                <button onClick={() => navigate('/')}>
                    <h1 className="text-xl sm:text-2xl font-extrabold tracking-wider">rps.live</h1>
                </button>
            </div>
            <button onClick={toggleMobileMenu} className="sm:hidden">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7"/>
                </svg>
            </button>
            <div
                className={`${showMobileMenu ? 'flex' : 'hidden'} sm:flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto mt-2 sm:mt-0`}>
                {authenticated ? (
                    <>
                        <button onClick={() => navigate('/')}
                                className="w-full sm:w-auto p-1 bg-gradient-to-r from-neon-blue to-neon-purple text-white rounded-full hover:shadow-neon-glow transition-all duration-300 transform hover:scale-110">
                            <HomeIcon className="h-4 w-4 mx-auto"/>
                        </button>
                        <button onClick={() => shareRoom()}
                                className="w-full sm:w-auto p-1 bg-gradient-to-r from-neon-blue to-neon-purple text-white rounded-full hover:shadow-neon-glow transition-all duration-300 transform hover:scale-110">
                            <ShareIcon className="h-4 w-4 mx-auto"/>
                        </button>
                        <div className="w-full sm:w-auto flex items-center justify-center">
                            <button onClick={() => setShowUsernamePopup(true)}
                                    className="flex items-center justify-center bg-gradient-to-r from-neon-blue to-neon-purple text-white px-2 py-1 rounded-full hover:shadow-neon-glow transition-all duration-300 transform hover:scale-105 font-extrabold uppercase tracking-wider text-xs w-full sm:w-auto">
                                <Edit className="mr-1 h-3 w-3 animate-spin-slow"/>
                                <span className="font-bold truncate max-w-[100px]">{playerData?.name}</span>
                            </button>
                        </div>
                        <button onClick={() => setShowLeaderboard(true)}
                                className="flex items-center justify-center bg-gradient-to-r from-neon-yellow to-neon-green text-white px-2 py-1 rounded-full hover:shadow-neon-glow transition-all duration-300 transform hover:scale-105 font-extrabold uppercase tracking-wider text-xs w-full sm:w-auto">
                            <Trophy className="mr-1 h-3 w-3 animate-pulse"/>
                            <span>Leaderboard</span>
                        </button>
                        <button ref={ruleRef} onClick={() => setShowRulesPopup(true)}
                                className="flex items-center justify-center bg-gradient-to-r from-neon-blue to-neon-purple text-white px-2 py-1 rounded-full hover:shadow-neon-glow transition-all duration-300 transform hover:scale-105 font-extrabold uppercase tracking-wider text-xs w-full sm:w-auto">
                            <Info className="mr-1 h-3 w-3 animate-pulse"/>
                            <span>Game Rules</span>
                        </button>
                        <button onClick={logout}
                                className="flex items-center justify-center bg-gradient-to-r from-neon-red to-neon-orange text-white px-2 py-1 rounded-full hover:shadow-neon-glow transition-all duration-300 transform hover:scale-105 font-extrabold uppercase tracking-wider text-xs w-full sm:w-auto">
                            <LogOut className="mr-1 h-3 w-3"/>
                            <span>Log Out</span>
                        </button>
                    </>
                ) : (
                    <button onClick={login}
                            className="flex items-center justify-center bg-gradient-to-r from-neon-blue to-neon-purple text-white px-3 py-1 rounded-full hover:shadow-neon-glow transition-all duration-300 transform hover:scale-110 font-extrabold uppercase tracking-wider animate-pulse text-xs w-full sm:w-auto">
                        <User className="mr-1 h-3 w-3 animate-bounce"/>
                        <span>Log In</span>
                    </button>
                )}
            </div>
            {showLeaderboard && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75"
                     onClick={() => setShowLeaderboard(false)}>
                    <div
                        className="max-h-[80vh] w-[90vw] md:w-[60vw] overflow-y-auto custom-scrollbar bg-spotify-black rounded-lg shadow-xl">
                        <GameLeaderboard/>
                    </div>
                </div>
            )}
            {showRulesPopup && (
                <GameRules onClose={() => setShowRulesPopup(false)}/>
            )}
            {showUsernamePopup && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/90 backdrop-blur-sm">
                    <div className="bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 p-8 rounded-2xl shadow-2xl border border-purple-500/30 w-[90vw] max-w-md animate-fade-in">
                        <h2 className="text-3xl font-black mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">Choose Your Identity</h2>
                        <input
                            type="text"
                            className="w-full p-4 border-2 border-purple-500/30 rounded-xl mb-6 bg-gray-900/80 text-white placeholder-gray-500 focus:outline-none focus:border-purple-500 focus:ring-2 focus:ring-purple-500/20 transition-all duration-300 text-lg"
                            placeholder="Enter your epic username"
                            value={usernameInput}
                            onChange={(e) => setUsernameInput(e.target.value)}
                        />
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={() => setShowUsernamePopup(false)}
                                className="px-6 py-3 rounded-xl bg-gray-800 text-gray-300 hover:bg-gray-700 hover:text-white transition-all duration-300 font-bold shadow-lg hover:shadow-gray-700/50"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={async () => {
                                    if (usernameInput) {
                                        if (user) {
                                            const userRef = doc(db, 'users', user.id);
                                            try {
                                                await updateDoc(userRef, {
                                                    name: usernameInput
                                                });
                                                console.log("Username updated successfully");
                                                setShowUsernamePopup(false);
                                            } catch (error) {
                                                console.error("Error updating username:", error);
                                            }
                                        } else {
                                            console.error("User is not authenticated");
                                        }
                                    }
                                }}
                                className="px-6 py-3 rounded-xl bg-gradient-to-r from-purple-600 to-pink-600 text-white hover:from-purple-500 hover:to-pink-500 transition-all duration-300 font-bold shadow-lg hover:shadow-purple-500/50 transform hover:scale-105"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const shareRoom = () => {
    // 构建当前页面的 URL
    let current_url = `${window.location.protocol}//${window.location.host}${window.location.pathname}}`;
    console.log(current_url);

    // 检查是否支持 Web Share API
    const isSupported = !!navigator.share;

    if (isSupported) {
        // 使用 Web Share API 进行分享
        navigator.share({
            title: 'RPS ALL OUT WAR',
            text: "I'm in an online rps duel and it's a lot of fun, so come and experience it too!",
            url: current_url,
        })
            .then(() => {
                console.log('Successfully shared');
            })
            .catch((error) => {
                console.error('Error sharing:', error);
            });
    } else {
        // 浏览器不支持 Web Share API，使用剪贴板功能
        console.log('Web Share API is not supported in this browser.');

        // 使用 Clipboard API 复制链接到剪贴板
        navigator.clipboard.writeText(current_url)
            .then(() => {
                message.success("Link copied to clipboard!");
            })
            .catch((error) => {
                console.error('Failed to copy the link:', error);
                message.error("Failed to copy the link to clipboard.");
            });
    }
};

export default Header;
