import React, {useCallback, useEffect, useState} from 'react';
import {onValue, ref, remove} from 'firebase/database';
import {rdb} from '../config/firebaseConfig';
import {Modal, Spin} from 'antd';
import {CloseCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import RoomCreate from "../components/RoomCreate";
import {PlusIcon} from "lucide-react";

const LobbyHome = ({playerData}) => {
    const [loading, setLoading] = useState(false);
    const [rooms, setRooms] = useState({});
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    async function deleteRoom(roomId: string) {
        try {
            const roomRef = ref(rdb, `rooms/${roomId}`);
            await remove(roomRef);
            console.log(`Room with ID ${roomId} has been deleted successfully.`);
        } catch (error) {
            console.error(`Failed to delete room with ID ${roomId}:`, error);
        }
    }

    const {confirm} = Modal;
    const showDeleteConfirm = (roomId) => {
        confirm({
            title: 'Are you sure you want to delete this room?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteRoom(roomId).then(r => console.log('Room deleted'));
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    const onLoadData = useCallback(() => {
        setLoading(true);
        const gamesRef = ref(rdb, 'rooms');

        onValue(gamesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setRooms(data);
            }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        onLoadData();
    }, [onLoadData]);

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-center items-center py-6">
                <div className="text-3xl font-bold text-white mr-4">Game Lobbies</div>
                {playerData?.admin?.add &&
                    (<button
                        onClick={handleOpenModal}
                        className="bg-gradient-to-br from-green-500 to-green-600 hover:from-green-400
                    hover:to-green-500 text-white px-4 py-2 rounded-lg cursor-pointer flex items-center
                    transition-all duration-300 hover:shadow-xl transform hover:scale-105">
                        <PlusIcon className="mr-2"/>
                        <span className="font-bold">Create</span>
                    </button>)}
            </div>
            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/> Loading...
                </div>
            ) : (
                <div
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-6 bg-gradient-to-br from-gray-900 to-black">
                    <Modal
                        title="Create New Room"
                        open={isModalOpen}
                        onCancel={handleCloseModal}
                        footer={null}
                        className="spotify-modal"
                    >
                        <RoomCreate closeModal={handleCloseModal}/>
                    </Modal>
                    {Object.keys(rooms)
                        .map((roomId) => {
                            const room = rooms[roomId];
                            const marketCap = ['Paper', 'Rock', 'Scissors'].reduce((total, item) => {
                                const itemData = room.market?.current?.[item];
                                return total + (itemData ? itemData.price * itemData.sold : 0);
                            }, 0);
                            return {roomId, room, marketCap};
                        })
                        .sort((a, b) => b.marketCap - a.marketCap)
                        .map(({roomId, room, marketCap}) => (
                            <div
                                onClick={() => navigate(`/${roomId}`)}
                                key={roomId}
                                className="relative bg-gray-800 hover:bg-gray-700 text-white p-6 rounded-lg cursor-pointer transition-all duration-300 hover:shadow-lg flex flex-col">
                                {playerData?.admin?.delete &&
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            showDeleteConfirm(roomId);
                                        }}
                                        className="absolute top-1 right-1 text-gray-400 hover:text-red-500 transition-all duration-300">
                                        <CloseCircleOutlined/>
                                    </button>}
                                <h2 className="text-xl font-bold mb-4 truncate">{room.lobbyState?.roomName}</h2>
                                <div className="flex-grow">
                                    <div className="mb-4">
                                        <div className="flex items-center justify-between mb-2">
                                            <span className="text-sm font-medium text-gray-300">Rounds</span>
                                            <span
                                                className="text-sm font-bold text-green-400">{room.games ? Object.keys(room.games)?.length ?? '0' : '0'}</span>
                                        </div>
                                        <div className="bg-gray-700 h-1 rounded-full">
                                            <div className="bg-green-400 h-1 rounded-full"
                                                 style={{width: `${Math.min((room.games ? Object.keys(room.games)?.length ?? 0 : 0) / 500 * 100, 100)}%`}}></div>
                                        </div>
                                    </div>
                                    <p className="text-sm font-semibold text-gray-300 mb-2">Market</p>
                                    <ul className="space-y-2">
                                        {['Paper', 'Rock', 'Scissors'].map((item) => (
                                            <li key={item}
                                                className="flex items-center justify-between bg-gray-700 rounded-lg p-2">
                                                <span className="text-xs font-medium text-gray-300">{item}</span>
                                                <span className="text-xs font-bold text-green-400">
                                                    {room.market?.current?.[item]
                                                        ? `$${room.market.current[item].price.toFixed(2)} (${room.market.current[item].sold})`
                                                        : 'N/A'}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="mt-4 text-xs text-gray-500 flex justify-between items-center">
                                    <span
                                        className="font-bold text-green-400">Market Cap: ${marketCap.toFixed(2)}</span>
                                    <span>Players: {room.players ? Object.keys(room.players).length : '0'}</span>
                                    <span className="bg-green-500 px-2 py-1 rounded-full text-white">Live</span>
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default LobbyHome
